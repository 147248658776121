<!--
 * @Author: your name
 * @Date: 2020-11-11 14:09:29
 * @LastEditTime: 2020-11-11 14:46:10
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \sd-vue-admin\src\views\project\baseOrder\aboutSatff\index.vue
-->
<template>
  <router-view></router-view>
</template>

<script>
  export default {
    name: 'AboutSatff',
  }
</script>
